<template>
	<div class="register-join">
		<div class="footer">
			<div class="footer-item">
				<van-button type="primary" round color="#3377FF" block @click="onVolunteer">加入平安志愿者</van-button>
			</div>
			<div class="footer-item">
				<van-button type="primary" round color="#3377FF" block @click="onPolice">加入义警</van-button>
			</div>
		</div>
	</div>
</template>

<script>
	import UserApi from '@/api/user';
	import Vant from '@/vendor/vant.js';
	export default{
		name:"RegisterJoin",
		data(){
			return{
				User:""
			}
		},
		created() {
			UserApi.getUserInfo()
				.then(result => {
					this.User = result.data;
				})
				.catch(error=>{
					console.log(error)
				})
		},
		methods:{
			
			onVolunteer(){
				if(this.User.id_card == ''){
					Vant.Dialog.confirm({
						title: '提示',
						message: '您还未注册，请先注册在加入!',
						confirmButtonText: '前往注册',
						cancelButtonText: '下次再说',
						closeOnPopstate: false
					}).then(() => {
						this.$router.push({name:"Register"})
					}).catch(() => {
					});
				}else if(this.User.role_type == 1){
					this.$router.push({name:"ApplyVigilante"})
				}else if(this.User.role_type == 2){
					Vant.Dialog.alert({
						title: '提示',
						message: "您已经是平安志愿者了!",
						theme: 'round-button',
					}).then(() => {
						this.$router.replace('/')
					});
				}else if(this.User.role_type == 3){
					Vant.Dialog.alert({
						title: '提示',
						message: "您已经是义警了!",
						theme: 'round-button',
					}).then(() => {
						this.$router.replace('/')
					});
				}
			},
			onPolice(){
				if(this.User.role_type == 2){
					this.$router.push({name:"ApplyVigilante"})
				}else if(this.User.id_card == ''){
					Vant.Dialog.confirm({
						title: '提示',
						message: '您还未注册，请先注册再加入!',
						confirmButtonText: '前往注册',
						cancelButtonText: '下次再说',
						closeOnPopstate: false
					}).then(() => {
						this.$router.push({name:"Register"})
					}).catch(() => {
					});
				}else if(this.User.role_type == 1){
					Vant.Dialog.alert({
						title: '提示',
						message: "请先加入平安志愿者!",
						theme: 'round-button',
					}).then(() => {
					});
				}else if(this.User.role_type == 3){
					Vant.Dialog.alert({
						title: '提示',
						message: "您已经是义警了!",
						theme: 'round-button',
					}).then(() => {
						this.$router.replace('/')
					});
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.register-join{
		width: 100%;
		height: 100vh;
		background: url('../../assets/img/wyjr.jpg');
		background-size: 100% 100%;
		box-sizing: border-box;
		position: relative;
		background-repeat:no-repeat;
		box-sizing: border-box;
		.footer{
			width: 100%;
			position: absolute;
			bottom: 20px;
			padding: 0 10px;
			box-sizing: border-box;
			.footer-item{
				margin-top: 20px;
				.van-button{
					font-size: 18px;
				}
			}
		}
	}
</style>